export const arr = [
  //沉降
  { obj: 'displacement', name: '测距值(mm)' },
  { obj: 'displacementChange', name: '累计位移(mm)' },
  //轴力
  { obj: 'measurements', name: '测量值(kg)' },
  { obj: 'axialForce', name: '轴力(N)' },
  { obj: 'axialForceChange', name: '累计变化(N)' },
  //倾角
  { obj: 'xAngleAccumulateChange', name: 'X倾角' },
  { obj: 'xAngleChange', name: 'X角度累计变化(°)' },
  { obj: 'xAngle', name: 'X角度(°)' },
  { obj: 'yAngleAccumulateChange', name: 'Y倾角' },
  { obj: 'yAngleChange', name: 'Y角度累计变化(°)' },
  { obj: 'yAngle', name: 'Y角度(°)' },
  //水平位移
  { obj: 'xDisplacementAccumulateChange', name: 'X方向累计位移(mm)' },
  { obj: 'yDisplacementAccumulateChange', name: 'Y方向累计位移(mm)' },
]
export function toName(obj) {
  var name = null
  arr.forEach((item) => {
    if (item.obj === obj) {
      name = item.name
      return
    }
  })
  return name
}
