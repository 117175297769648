<template>
  <v-chart ref="lineChart" :option="orgOptions" :autoresize="true" style="width: 100%; height: 100%"></v-chart>
</template>

<script>
import 'echarts/lib/chart/line'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/toolbox'
import 'echarts/theme/macarons'
import { toName } from '@/utils/objToName.js'

export default {
  data() {
    return {
      orgOptions: {},
      xAxisData: [],
      bool: false,
      seriesData: [],
      name: null,
    }
  },
  props: {
    tableData: Array,
    itemObject: String,
  },
  methods: {
    handleTableDataChange() {
      let newData = this.tableData

      this.seriesData = []

      newData.forEach((item) => {
        let information = []
        if (this.itemObject === 'displacement') {
          information = item.data.filter((e) => {
            return Math.abs(e.displacement) < 3000000
          })
        } else {
          information = item.data
        }
        let information_handle = information.map((ele) => {
          return [ele.createTime, ele[this.itemObject]]
        })

        const obj = {
          name: item.name,
          type: 'line',
          data: information_handle,
          showSymbol: false,
        }
        this.seriesData.push(obj)
      })
    },

    setOrgOptions() {
      this.$nextTick(() => {
        this.$refs.lineChart.setOption(
          {
            title: {
              show: true,
              text: this.name,
              top: '2%',
              left: '1%',
            },
            backgroundColor: '#fff',
            tooltip: {
              show: true,
              trigger: 'axis',
              axisPointer: {
                animation: false,
              },
            },
            legend: {
              top: '2%',
              right: '5%',
            },
            dataZoom: [{ type: 'inside' }],
            grid: {
              top: '10%',
              left: '1%',
              right: '5%',
              bottom: '2%',
              show: true,
              borderColor: '#012f4a',
              containLabel: true,
            },
            toolbox: {
              feature: {
                saveAsImage: {},
              },
            },
            xAxis: {
              type: 'time',
              boundaryGap: false,
            },
            yAxis: {
              type: 'value',
              // 去除刻度
              axisTick: {
                show: false,
              },
              // 修饰刻度标签的颜色
              axisLabel: {
                color: 'rgba(0,0,0,.7)',
              },
              // 修改y轴分割线的颜色
              splitLine: {
                lineStyle: {
                  color: '#012f4a',
                  // color: '#ccc',
                },
              },
            },

            series: this.seriesData,
          },
          true
        )
      })
    },
  },
  watch: {
    tableData: {
      handler() {
        this.name = toName(this.itemObject)
        this.handleTableDataChange()
        this.setOrgOptions()
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
