<template>
  <div class="chart">
    <el-scrollbar>
      <div class="line_box" v-loading="loading">
        <lineChart ref="lineChart" :itemObject="itemProtocol" :tableData="tableData"></lineChart>
      </div>

      <el-row>
        <el-select v-model="itemType" filterable placeholder="请选择检测项" style="margin-right: 10px; margin-bottom: 10px">
          <el-option v-for="item in allGroupInfo" :key="item.itemType" :label="item.deviceProtocolName" :value="item.itemType"> </el-option>
        </el-select>

        <el-select style="margin-right: 10px; margin-bottom: 10px" v-model="itemProtocol" filterable placeholder="请选择查看监测项">
          <el-option v-for="item in protocolList" :key="item.object" :label="item.name" :value="item.object"> </el-option>
        </el-select>

        <el-select style="margin-right: 10px; margin-bottom: 10px" v-model="itemIds" multiple collapse-tags filterable placeholder="查看数据曲线">
          <el-option v-for="item in itemList" :key="item.pkItemId" :label="item.pointName" :value="item.pkItemId"> </el-option>
        </el-select>

        <el-date-picker style="margin-right: 10px; margin-bottom: 10px" :picker-options="pickerOptions" v-model="dayTime" type="datetimerange" unlink-panels format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
        </el-date-picker>

        <el-button class="el-icon-search" style="margin-right: 10px; margin-bottom: 10px" @click="findDataFun">查询数据</el-button>
        <el-button class="el-icon-refresh" style="margin-right: 10px; margin-bottom: 10px" @click="findDataFunNew">刷新数据</el-button>
      </el-row>
    </el-scrollbar>
  </div>
</template>

<script>
import lineChart from './firstChart.vue'
import { findAllPoint } from '@/api/Point.js'
import { findAllPointV3 } from '@/api/PointV3.js'
import { findResultByIds } from '@/api/data.js'
import '@/utils/time.js'

const allGroupInfo = [
  {
    deviceProtocolName: 'V_沉降监测',
    deviceProtocol: 'HighMoldLaserVItem',
    groupName: '沉降',
    itemType: 'V',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测',
    deviceProtocol: 'HighMoldAxialFItem',
    groupName: '轴力',
    itemType: 'F',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测',
    deviceProtocol: 'HighMoldInclinationLItem',
    groupName: '倾角',
    itemType: 'L',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测',
    deviceProtocol: 'HighMoldLaserHItem',
    groupName: '位移',
    itemType: 'H',
    nodeType: 'HL',
    data: [],
  },

  {
    deviceProtocolName: 'V_沉降监测(V3)',
    deviceProtocol: 'HighMoldLaserVItemV3',
    groupName: '沉降',
    itemType: 'VV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测(V3)',
    deviceProtocol: 'HighMoldAxialFItemV3',
    groupName: '轴力',
    itemType: 'FV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测(V3)',
    deviceProtocol: 'HighMoldInclinationLItemV3',
    groupName: '倾角',
    itemType: 'LV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测(V3)',
    deviceProtocol: 'HighMoldLaserHItemV3',
    groupName: '位移',
    itemType: 'HV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
]

let defaultStart = new Date()
defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 48)
defaultStart = defaultStart.format('yyyy-MM-dd hh:mm:ss')

export default {
  components: { lineChart },
  data() {
    return {
      allGroupInfo,

      protocol: 'HighMoldLaserVItem',
      itemType: 'V',
      dayTime: [defaultStart, new Date().format('yyyy-MM-dd hh:mm:ss')],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近两小时',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 2)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      itemList: [],
      itemListActive: [],
      itemIds: null,
      type: 'Result',
      titleText: null,
      bool: false,
      itemProtocol: '',
      protocolList: [],
      loading: true,
      tableData: [],
    }
  },
  watch: {
    itemType(e) {
      this.handleData(e)
      this.getSelectData(e)
    },
  },
  methods: {
    // 查询任务所有测点
    async findAllPointFun() {
      console.log('执行了............')
      const { data: res } = await findAllPoint(this.$route.params.projectId - 0)
      const { data: resV3 } = await findAllPointV3(this.$route.params.projectId - 0)

      console.log('resV3', resV3)

      this.allGroupInfo[0].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '沉降监测'
      })
      this.allGroupInfo[1].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo[2].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '倾角监测'
      })
      this.allGroupInfo[3].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '位移监测'
      })

      this.allGroupInfo[4].data = resV3.data.filter((item) => {
        return item.monitoringType === '沉降监测'
      })
      this.allGroupInfo[5].data = resV3.data.filter((item) => {
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo[6].data = resV3.data.filter((item) => {
        return item.monitoringType === '倾角监测'
      })
      this.allGroupInfo[7].data = resV3.data.filter((item) => {
        return item.monitoringType === '位移监测'
      })

      this.getSelectData(this.allGroupInfo[0].itemType)
    },
    findDataFunNew() {
      let defaultStart = new Date()
      defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 24 * 2)
      defaultStart = defaultStart.format('yyyy-MM-dd hh:mm:ss')
      this.dayTime = [defaultStart, new Date().format('yyyy-MM-dd hh:mm:ss')]
      this.findDataFun()
    },
    // 获取数据
    async findDataFun() {
      this.loading = true

      if (this.itemIds.length <= 0) {
        this.loading = false
        return
      }
      const { data: res } = await findResultByIds(this.itemType, this.itemIds.join(), this.dayTime[0], this.dayTime[1])
      const dataList = []
      for (let key in res.data) {
        dataList.push({
          name: key,
          data: res.data[key],
        })
      }
      this.tableData = dataList
      this.loading = false
    },

    // 初始化
    async init() {
      await this.findAllPointFun()
      await this.findDataFun()
    },
    getSelectData(e) {
      const data = this.allGroupInfo.filter((item) => {
        return item.itemType === e
      })
      this.itemIds = []

      this.itemList = data[0].data
      this.protocol = data[0].deviceProtocol

      if (this.itemList.length === 0) {
        this.$message({
          message: '该监测项无测点，请切换检测项重试！！',
          type: 'warning',
        })
        return
      }

      this.itemList.forEach((item, index) => {
        if (index < 20) {
          this.itemIds.push(item.pkItemId)
        } else {
          return
        }
      })
      this.findDataFun()
    },

    handleDataList(list) {
      const listAll = []

      list.forEach((item) => {
        console.log(item)
        const dataList = []
        if (item.data.length > 0) {
          item.data.forEach((ele) => {
            dataList.push(ele[this.itemProtocol])
          })
        }
        const itemData = {
          name: item.name,
          type: 'line',
          smooth: true,
          data: dataList,
        }

        listAll.push(itemData)
      })

      this.seriesData = listAll
    },

    handleData(type) {
      switch (type) {
        case 'F':
          this.protocolList = [
            {
              name: '测量值(kg)',
              object: 'measurements',
            },
            {
              name: '轴力(N)',
              object: 'axialForce',
            },
            {
              name: '累计变化(N)',
              object: 'axialForceChange',
            },
          ]
          break
        case 'L':
          this.protocolList = [
            {
              name: 'X角度(°)',
              object: 'xAngle',
            },
            {
              name: 'X倾角(‰)',
              object: 'xAngleAccumulateChange',
            },
            {
              name: 'X角度累计变化(°)',
              object: 'xAngleChange',
            },
            {
              name: 'Y角度(°)',
              object: 'yAngle',
            },
            {
              name: 'Y倾角(‰)',
              object: 'yAngleAccumulateChange',
            },
            {
              name: 'Y角度累计变化(°)',
              object: 'yAngleChange',
            },
          ]

          break
        case 'H':
          this.protocolList = [
            {
              name: 'X方向累计位移(mm)',
              object: 'xDisplacementAccumulateChange',
            },
            {
              name: 'Y方向累计位移(mm)',
              object: 'yDisplacementAccumulateChange',
            },
          ]
          break
        case 'V':
          this.protocolList = [
            {
              name: '测距值(mm)',
              object: 'displacement',
            },
            {
              name: '累计位移(mm)',
              object: 'displacementChange',
            },
          ]
          break
        case 'FV3':
          this.protocolList = [
            {
              name: '测量值(kg)',
              object: 'measurements',
            },
            {
              name: '轴力(N)',
              object: 'axialForce',
            },
            {
              name: '累计变化(N)',
              object: 'axialForceChange',
            },
          ]
          break
        case 'LV3':
          this.protocolList = [
            {
              name: 'X角度(°)',
              object: 'xAngle',
            },
            {
              name: 'X倾角(‰)',
              object: 'xAngleAccumulateChange',
            },
            {
              name: 'X角度累计变化(°)',
              object: 'xAngleChange',
            },
            {
              name: 'Y角度(°)',
              object: 'yAngle',
            },
            {
              name: 'Y倾角(‰)',
              object: 'yAngleAccumulateChange',
            },
            {
              name: 'Y角度累计变化(°)',
              object: 'yAngleChange',
            },
          ]

          break
        case 'HV3':
          this.protocolList = [
            {
              name: 'X方向累计位移(mm)',
              object: 'xDisplacementAccumulateChange',
            },
            {
              name: 'Y方向累计位移(mm)',
              object: 'yDisplacementAccumulateChange',
            },
          ]
          break
        case 'VV3':
          this.protocolList = [
            {
              name: '测距值(mm)',
              object: 'displacement',
            },
            {
              name: '累计位移(mm)',
              object: 'displacementChange',
            },
          ]
      }
      this.itemProtocol = this.protocolList[0].object
    },
  },
  created() {
    this.handleData(this.itemType)

    this.init()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
// .chart{
//    height: 100%;
//   //  overflow: hidden;
//     .el-scrollbar {
//       height: 100%;
//     }

//     .el-scrollbar ::v-deep.el-scrollbar__wrap {
//       overflow: scroll;
//       height: 100% !important;
//       overflow-x: hidden !important;
//       padding: 10px 15px;
//       padding-bottom: 20px;
//     }
// }
.line_box {
  width: 100%;
  height: 600px !important;
}

.lineChart {
  width: 100%;
  height: 100%;
}

.el-row {
  padding-top: 20px;
  padding-left: 50px;
}
</style>
