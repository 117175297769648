<template>
  <div class="chart">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="单点多项曲线分析" name="second">
        <Second></Second>
      </el-tab-pane>
      <el-tab-pane label="多点单项曲线分析" name="first">
        <First></First>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import First from './first.vue'
import Second from './second.vue'
export default {
  components: { First, Second },
  data() {
    return {
      activeName: 'first',
    }
  },
  methods: {
    handleClick() {},
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss">
.chart {
  padding: 10px;
}
</style>
