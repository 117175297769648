<template>
  <div style="width: 100%; height: 100%">
    <v-chart ref="lineChart" :option="orgOptions" :autoresize="true" style="width: 100%; height: 100%" @contextmenu="contextmenuFunc"></v-chart>
  </div>
</template>

<script>
import 'echarts/lib/chart/line'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/toolbox'
import 'echarts/theme/macarons'
import { arr, toName } from '@/utils/objToName'
import { abnormalValueDel } from '@/api/Alarm.js'
// import
export default {
  data() {
    return {
      orgOptions: {},
      xAxisData: [],
      bool: false,
      seriesData: [],
    }
  },
  props: {
    tableData: Array,
    itemType: String,
  },
  methods: {
    // handleTableDataChange() {
    //   const newData = this.tableData
    //   this.series = newData.map((item) => {
    //     return item[this.itemObject]
    //   })
    //   this.timeList = newData.map((item) => {
    //     return item.createTime
    //   })
    // },

    handleTableDataChange() {
      const type = this.itemType
      let list = []

      if (type === 'V') {
        // 沉降测距值数据 异常数据 影响报警线 因此排除异常数据
        list = this.tableData.filter((item) => {
          return Math.abs(item.displacement) < 3000000
        })
      } else {
        list = this.tableData
      }

      this.seriesData = []
      this.xAxisData = []
      switch (type) {
        case 'F':
          this.seriesData = [
            {
              name: '测量值(kg)',
              type: 'line',
              showSymbol: false,
              data: [],
            },
            {
              name: '轴力(N)',
              type: 'line',
              showSymbol: false,
              data: [],
            },
            {
              name: '累计变化(N)',
              type: 'line',
              showSymbol: false,
              data: [],
            },
          ]
          list.forEach((item) => {
            this.seriesData[0].data.push([item.createTime, item.itemObject])
            this.seriesData[1].data.push([item.createTime, item.axialForce])
            this.seriesData[2].data.push([item.createTime, item.axialForceChange])
          })
          break
        case 'L':
          this.seriesData = [
            {
              name: 'X角度(°)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'X角度累计变化(°)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'X倾角(‰)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'Y角度(°)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'Y角度累计变化(°)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'Y倾角(‰)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
          ]
          list.forEach((item) => {
            this.seriesData[0].data.push([item.createTime, item.xAngle])
            this.seriesData[1].data.push([item.createTime, item.xAngleChange])
            this.seriesData[2].data.push([item.createTime, item.xAngleAccumulateChange])
            this.seriesData[3].data.push([item.createTime, item.yAngle])
            this.seriesData[4].data.push([item.createTime, item.yAngleChange])
            this.seriesData[5].data.push([item.createTime, item.yAngleAccumulateChange])
          })

          break
        case 'H':
          this.seriesData = [
            {
              name: 'X方向累计位移(mm)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'Y方向累计位移(mm)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
          ]
          list.forEach((item) => {
            this.seriesData[0].data.push([item.createTime, item.xDisplacementAccumulateChange])
            this.seriesData[1].data.push([item.createTime, item.yDisplacementAccumulateChange])
          })
          break
        case 'V':
          this.seriesData = [
            {
              name: '测距值(mm)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: '累计位移(mm)',
              type: 'line',
              showSymbol: false,
              data: [],
            },
          ]
          list.forEach((item) => {
            this.seriesData[0].data.push([item.createTime, item.displacement])
            this.seriesData[1].data.push([item.createTime, item.displacementChange])
          })
          break
        case 'FV3':
          this.seriesData = [
            {
              name: '测量值(kg)',
              type: 'line',
              showSymbol: false,
              data: [],
            },
            {
              name: '轴力(N)',
              type: 'line',
              showSymbol: false,
              data: [],
            },
            {
              name: '累计变化(N)',
              type: 'line',
              showSymbol: false,
              data: [],
            },
          ]
          list.forEach((item) => {
            this.seriesData[0].data.push([item.createTime, item.itemObject])
            this.seriesData[1].data.push([item.createTime, item.axialForce])
            this.seriesData[2].data.push([item.createTime, item.axialForceChange])
          })
          break
        case 'LV3':
          this.seriesData = [
            {
              name: 'X角度(°)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'X角度累计变化(°)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'X倾角(‰)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'Y角度(°)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'Y角度累计变化(°)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'Y倾角(‰)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
          ]
          list.forEach((item) => {
            this.seriesData[0].data.push([item.createTime, item.xAngle])
            this.seriesData[1].data.push([item.createTime, item.xAngleChange])
            this.seriesData[2].data.push([item.createTime, item.xAngleAccumulateChange])
            this.seriesData[3].data.push([item.createTime, item.yAngle])
            this.seriesData[4].data.push([item.createTime, item.yAngleChange])
            this.seriesData[5].data.push([item.createTime, item.yAngleAccumulateChange])
          })

          break
        case 'HV3':
          this.seriesData = [
            {
              name: 'X方向累计位移(mm)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: 'Y方向累计位移(mm)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
          ]
          list.forEach((item) => {
            this.seriesData[0].data.push([item.createTime, item.xDisplacementAccumulateChange])
            this.seriesData[1].data.push([item.createTime, item.yDisplacementAccumulateChange])
          })
          break
        case 'VV3':
          this.seriesData = [
            {
              name: '测距值(mm)',
              type: 'line',
              data: [],
              showSymbol: false,
            },
            {
              name: '累计位移(mm)',
              type: 'line',
              showSymbol: false,
              data: [],
            },
          ]
          list.forEach((item) => {
            this.seriesData[0].data.push([item.createTime, item.displacement])
            this.seriesData[1].data.push([item.createTime, item.displacementChange])
          })
          break
      }
    },

    setOrgOptions() {
      let itemName = null
      switch (this.itemType) {
        case 'V':
          itemName = '沉降监测'
          break
        case 'F':
          itemName = '轴力监测'
          break
        case 'L':
          itemName = '倾角监测'
          break
        case 'H':
          itemName = '水平位移监测'
          break
      }
      this.orgOptions = this.$nextTick(() => {
        this.$refs.lineChart.setOption(
          {
            title: {
              show: true,
              text: itemName,
              top: '2%',
              left: '1%',
            },
            backgroundColor: '#fff',
            tooltip: {
              show: true,
              trigger: 'axis',
              axisPointer: {
                animation: false,
              },
            },
            toolbox: {
              feature: {
                saveAsImage: {},
              },
            },
            legend: {
              top: '2%',
              right: '5%',
            },
            grid: {
              top: '10%',
              left: '1%',
              right: '5%',
              bottom: '2%',
              show: true,
              borderColor: '#012f4a',
              containLabel: true,
            },
            dataZoom: [{ type: 'inside' }],
            xAxis: {
              type: 'time',
              boundaryGap: false,
            },
            yAxis: {
              type: 'value',
              // 去除刻度
              axisTick: {
                show: false,
              },
              // min: 0,
              // max: 20000,
              // 修饰刻度标签的颜色
              axisLabel: {
                color: 'rgba(0,0,0,.7)',
              },
              // 修改y轴分割线的颜色
              splitLine: {
                lineStyle: {
                  color: '#012f4a',
                  // color: '#ccc',
                },
              },
            },

            series: this.seriesData,
          },
          true
        )
      })
    },

    async handleAbnormal(id) {
      const { data: res } = await abnormalValueDel(this.itemType, id)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.findDataFun()
    },

    contextmenuFunc(param) {
      if (this.$store.state.power < 3) {
        return
      }
      const currentData = this.tableData.find((item) => {
        return item.createTime === param.data[0]
      })
      console.log(currentData)
      let text = ''
      arr.forEach((item) => {
        if (currentData[item.obj]) {
          text += toName(item.obj) + ':' + currentData[item.obj] + '<br/>'
        }
      })
      this.$confirm(text, currentData.point.pointName + '(批次:' + currentData.dataBatch + ')     ' + currentData.createTime, {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '异常处理',
        cancelButtonText: '取消',
      })
        .then(() => {
          // console.log(currentData.pkResultId)
          this.handleAbnormal(currentData.pkResultId)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消处理',
          })
        })
    },
  },
  watch: {
    tableData: {
      handler() {
        this.handleTableDataChange()
        this.setOrgOptions()
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    if (this.$store.state.power >= 3) {
      document.oncontextmenu = function (e) {
        if (e.target.nodeName === 'CANVAS') {
          return false
        }
      }
    }
  },
}
</script>

<style scoped lang="scss"></style>
